import React from 'react'
import styled from 'styled-components'
import { Alert as MuiAlert, AlertProps } from '@material-ui/lab'
import { spacing as MuiSpacing } from '@material-ui/system'
import { Typography as MuiTypography } from '@material-ui/core'

interface CustomAlertBannerProps extends AlertProps {
    width?: string
}

export const AlertBanner: React.FC<CustomAlertBannerProps> = styled(styled(MuiAlert)(MuiSpacing))<CustomAlertBannerProps>`
    color: ${(props): number => props.theme.palette.black.main};
    right: ${(props): string => props.theme.spacing(6)}px;
    border-radius: 0;
    padding: ${(props): string => props.theme.spacing(6)}px;
    justify-content: space-between;
    & > div{
        &:nth-child(1){
            margin-right: 24px;

        }

        &:nth-child(2){
            padding: ${(props): string => props.theme.spacing(2)}px 0 0 0;
            width: 100%;

            & > .alertbanner-outer-container{
                display: flex;

                & > .alertbanner-inner-container-left{
                    flex-grow: 1;
                    margin-right: ${(props): string => props.theme.spacing(6)}px;

                    & > .alertbanner-title{
                        & h6{
                            padding-bottom: ${(props): string => props.theme.spacing(2)}px;
                        }
                    }
                    & > .alertbanner-message{
                        & p{
                            width: 100%;
                        }
                    }
                }

                & > .alertbanner-inner-container-right{
                    display: flex;
                    flex-wrap: wrap;
                    align-content: center;

                    & > div{
                        display: flex;
                        flex-wrap: nowrap;
                        & button:nth-child(2){
                            margin-left: ${(props): string => props.theme.spacing(3)}px;
                        }
                    }
                }
            }
        }

        /* Close icon */
        &:nth-child(3){
            width: 24px;
            display: block;
            margin-left: ${(props): string => props.theme.spacing(6)}px;
            margin-top: 3px;
            margin-right: unset;
            padding: 0;

            & svg{
                font-size: 22px;
            }
        }
    }

    /* Colors for icon and border depending on the severity */
    &.alertbanner-info{
        ${(props): string => props.width ? `width: ${props.width}` : ''};
        border: 1px solid ${(props): number => props.theme.palette.info300.main};
        & div > svg{
            color: ${(props): number => props.theme.palette.info600.main};
        }
    }
    &.alertbanner-success{
        ${(props): string => props.width ? `width: ${props.width}` : ''};
        border: 1px solid ${(props): number => props.theme.palette.success300.main};
        & div > svg{
            color: ${(props): number => props.theme.palette.success600.main};
        }
    }
    &.alertbanner-warning{
        ${(props): string => props.width ? `width: ${props.width}` : ''};
        border: 1px solid ${(props): number => props.theme.palette.warning600.main};
        & div > svg{
            color: ${(props): number => props.theme.palette.warning900.main};
        }
    }
    &.alertbanner-error{
        ${(props): string => props.width ? `width: ${props.width}` : ''};
        border: 1px solid ${(props): number => props.theme.palette.error200.main};
        & div > svg{
            color: ${(props): number => props.theme.palette.error900.main};
        }
    }

    @media only screen and (max-width: 960px){
        & > div{
            &:nth-child(2){
                & > .alertbanner-outer-container{
                    display: block;

                    & > .alertbanner-inner-container-right{
                        display: block;

                        /* Action buttons */
                        & > div{
                            justify-content: flex-end;
                        }
                    }
                }
            }
        }
    }
`

export const Typography = styled(styled(MuiTypography)(MuiSpacing))``
