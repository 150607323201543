import React, { useEffect } from "react";
import { Checkbox, Typography, ContentSpan } from "./SingleCheckbox.styled";
import { Controller, Control, FieldValues } from 'react-hook-form'
import { FieldConfig } from "../FormSimple";

export interface SingleCheckboxProps {
    name: string
    control: Control<FieldValues>
    checkboxText: string | React.ReactNode
    values: boolean
    setValue: (name: string, value: any, options?: any) => void
    getValues: (name?: string, options?: any) => void
    trigger: (name: string) => Promise<boolean>
    debouncedHandleOnChange: () => void
    field: FieldConfig
    errors?: any
    error?: any
    helperText?: any
}

const SingleCheckbox: React.FunctionComponent<SingleCheckboxProps> = ({
    name,
    field,
    checkboxText,
    control,
    errors,
    values,
    error,
    helperText,
    setValue,
    getValues,
    trigger,
    debouncedHandleOnChange,
}) => {
    return <Controller
                name={name ? name : field.name}
                control={control}
                defaultValue={field.defaultValue || ''}
                render={({field}) => {
                    return <>
                        <Typography variant='body2' color='textSecondary'>
                            <Checkbox
                                onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                                    const newValue = e.target.checked
                                    field.onChange(newValue)
                                    setValue(field.name, newValue, { shouldValidate: false })
                                    trigger(field.name).then(() => {
                                        const isFieldValid = !errors[field.name]
                                        if (isFieldValid && field.onChange) {
                                            field.onChange(newValue)
                                            setValue(field.name, newValue, {
                                                shouldValidate: false,
                                            })
                                            debouncedHandleOnChange()
                                        }
                                    })
                                }}
                                checked={field.value}
                            />
                            <ContentSpan>{checkboxText}</ContentSpan>
                        </Typography>
                    </>
                }}
            />
    
    
    
    
}

export default SingleCheckbox;
