import {
    Typography as MuiTypography,
    Checkbox as MuiCheckbox
} from '@material-ui/core'
import { spacing as MuiSpacing } from '@material-ui/system'
import styled from 'styled-components'

export const Checkbox = styled(MuiCheckbox)`
    margin-right: 8px;
    padding: 0;
`

export const Typography = styled(styled(MuiTypography)(MuiSpacing))`
`

export const ContentSpan = styled(styled.span(MuiSpacing))`
    color: black;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.1876em;
`