import React from 'react'
import { Controller, Control, FieldValues } from 'react-hook-form'
import { Typography, Grid } from '@material-ui/core'
import LabelledOutline from '../LabelledOutline/LabelledOutline'
import { loadComponent } from '../Form/helpers'
import { GridTooltipFlex, QuestionIcon } from '../Tooltip/Tooltip.styled'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Tooltip, { CustomTooltipProps } from '../Tooltip/Tooltip'
import InputForm from '../Form/InputForm/InputForm'
import LabelledOutlineBox from '../Form/LabelledOutLineBox/LabelledOutLineBox'

interface GroupInputsProps {
  namePrefix: string
  control: Control<FieldValues>
  
  errors: any
  labelName?: string
  additionalDescription?: string
  
  setValue: (name: string, value: any, options?: any) => void
  
  getValues: (name?: string, options?: any) => void
  trigger: (name: string) => Promise<boolean>
  debouncedHandleOnChange?: () => void
  
  groupInputs?: Record<string, any>
  tooltip?: CustomTooltipProps
  hideOutBox?: boolean
}

const GroupInputs: React.FC<GroupInputsProps> = ({
  namePrefix,
  control,
  errors,
  labelName,
  setValue,
  getValues,
  trigger,
  debouncedHandleOnChange,
  additionalDescription,
  groupInputs,
  tooltip,
  hideOutBox,
}) => {


  return (
    <>
      <LabelledOutlineBox labelName={labelName ? labelName : namePrefix} tooltip={tooltip} hideOutBox={hideOutBox}>
        <Grid container spacing={2}>
          {groupInputs &&
            Object.keys(groupInputs).map((fieldName) => {
              const error = errors[`${namePrefix}`]

              groupInputs[fieldName].labelName = groupInputs[fieldName].label  ? groupInputs[fieldName].label : fieldName
            
              const field = groupInputs[fieldName]
              return (
                <Grid
                  item
                  {...groupInputs[fieldName].layoutConfig}
                  key={fieldName}
                >
                  <GridTooltipFlex>
                    <InputForm
                      key={`${namePrefix}.${fieldName}`}
                      name={`${namePrefix}.${fieldName}`}
                      field={field}
                      control={control}
                      error={!!error?.[`${fieldName}`]} // Ensure correct error access
                      helperText={error?.[`${fieldName}`]?.message}
                      setValue={setValue}
                      getValues={getValues}
                      trigger={trigger}
                      debouncedHandleOnChange={
                        debouncedHandleOnChange
                          ? debouncedHandleOnChange
                          : () => {}
                      }
                    />
                    {groupInputs[fieldName]?.tooltip && (
                      <Tooltip {...groupInputs[fieldName]?.tooltip}>
                        <QuestionIcon>
                          <FontAwesomeIcon
                            icon={
                              groupInputs[fieldName]?.tooltip?.icon
                                ? groupInputs[fieldName]?.tooltip?.icon
                                : ['far', 'question-circle']
                            }
                          />
                        </QuestionIcon>
                      </Tooltip>
                    )}
                  </GridTooltipFlex>
                </Grid>
              )
            })}
          {errors[`${namePrefix}`] && (
            <Typography variant='caption' color='error'>
              {errors[`${namePrefix}`]?.message}
            </Typography>
          )}

          {additionalDescription && (
            <Typography variant='caption' color='primary'>
              {additionalDescription}
            </Typography>
          )}
        </Grid>
      </LabelledOutlineBox>
    </>
  )
}

export default GroupInputs
