import { Box, Typography, TypographyProps, Grid as MuiGrid, TextField as MuiTextField, makeStyles } from '@material-ui/core'
import styled from 'styled-components'

export const TextField = styled(MuiTextField)<{$hasError?: boolean}>`
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-inner-spin-button,
    input::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type=number] {
        -moz-appearance: textfield;
    }

    &.billing-contact-emails label {
        left: -10px;
        ${(props): string => props.$hasError ? `color: ${props.theme.palette.error900.main}` : ``};
    }

    &.billing-contact-emails p {
        margin-left: 0px;
    }

    &.billing-contact-emails input {
        margin-left: -10px;
    }

    & [class^='MuiChip-root'] {
        margin-left: -5px;
        margin-right: 10px;
    }

    & [class^='MuiInputBase-root'] {
        background: white !important;
    }

    & [class^='MuiInputBase-root']::before {
        ${(props): string => props.$hasError ? `border-color: ${props.theme.palette.error900.main}` : `rgba(0, 0, 0, 0.42)`};
        ${(props): string => props.$hasError ? `border-width: 2px` : `border-width: 1px`};
    }

    & [class^='MuiInputBase-root']::after {
        ${(props): string => props.$hasError ? `border-color: ${props.theme.palette.error900.main}` : `#005457`};
        ${(props): string => props.$hasError ? `border-width: 2px` : `border-width: 1px`};
    }

    & [class^='MuiFormHelperText-root'] {
        ${(props): string => props.$hasError ? `color: ${props.theme.palette.error900.main}` : ``};
    }
`