import { logger } from '../../logger'
import { AddressBookService, ApiError, Country, ErrorResponse, UuidV4 } from '@lazr/openapi-client'
import { Address, AddressAttributes, BillingAddressAttributes, EditAddressResponse, FilteredAddresses } from '../../model/Address'
import { handleUnauthorizedException } from './index'
// import { isAddressComplete } from '../../ui/lib/helpers'
import { Currency } from '@lazr/enums'
import { isAddressComplete } from '@/app/ui-new/pages/marketplace/AdditionalDetails/types/Helpers'

export type AddressOrderByField = 'name' | 'companyName' | 'description' | 'contactName' | 'streetAddressLine1'
export type AddressOrder = 'asc' | 'desc'

export interface AddressBookFilter {
    isShipping?: boolean
    isBilling?: boolean
    isBillingDefault?: boolean
    isShippingDefault?: boolean
    searchField?: string
    name?: string
    address?: string
    description?: string
    city?: string
    state?: string
    postalCode?: string
    country?: string
    companyName?: string
    organizationId?: UuidV4
    excludeAddressId?: UuidV4
    orderBy?: AddressOrderByField
    order?: AddressOrder
}

export interface AddressBookPaging {
    page?: number
    resultPerPage?: number
}

export const AddressApiService = Object.freeze({
    add: async (addressAttributes: AddressAttributes): Promise<UuidV4> => {
        if (!isAddressComplete(addressAttributes)) {
            throw new Error('Missing some address attributes')
        }

        let addAddressResponse
        try {
            addAddressResponse = await AddressBookService.addAddress({
                name: addressAttributes.userSpecifiedId,
                description: addressAttributes.description,
                streetAddress1: addressAttributes.streetAddress,
                streetAddress2:  addressAttributes.streetAddress2,
                streetAddress3:  addressAttributes.streetAddress3,
                city: addressAttributes.city,
                state: addressAttributes.state,
                country: addressAttributes.country ?? Country.CA,
                postalCode: addressAttributes.postalCode,
                addressType: addressAttributes.addressType,
                detectedAddressType: addressAttributes.detectedAddressType ?? null,
                companyName: addressAttributes.companyName,
                companyLegalName: addressAttributes.companyName,
                shippingContactName: addressAttributes.shippingContactName,
                shippingContactEmails: addressAttributes.shippingContactEmails,
                shippingContactPhone: addressAttributes.shippingContactPhone ?? null,
                shippingContactPhoneExtension: addressAttributes.shippingContactPhoneExtension,
                billingContactName: addressAttributes.billingContactName,
                billingContactEmails: addressAttributes.billingContactEmails,
                billingContactPhone: addressAttributes.billingContactPhone,
                billingContactPhoneExtension: addressAttributes.billingContactPhoneExtension,
                notify: addressAttributes.notify,
                instructions: addressAttributes.instructions,
                openTime: addressAttributes.openTime,
                closeTime: addressAttributes.closeTime,
                accessorials: addressAttributes.accessorials.length === 0 ? null : addressAttributes.accessorials.map((accessorial) =>
                    ({
                        accessorialId: accessorial.accessorial.id,
                        isRemovable: accessorial.isRemovable,
                    })),
                isShipping: addressAttributes.isShipping,
                isBilling: addressAttributes.isBilling,
                isBillingDefault: addressAttributes.isBillingDefault,
                isShippingDefault: addressAttributes.isShippingDefault,
                collectAccounts: addressAttributes.collectAccounts,
            })
        } catch (error: any) {
            handleUnauthorizedException(error)
            logger.debug('add', JSON.stringify(error, null, 4))
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                throw new Error(errorResponse.error?.message || 'Unable to add address')
            }
            throw new Error('Unable to add address')
        }

        if (!addAddressResponse.data) {
            logger.debug('add', JSON.stringify(addAddressResponse, null, 4))
            throw new Error('Unable to add address')
        }

        return addAddressResponse.data.id
    },
    edit: async (addressAttributes: AddressAttributes, skipValidation = false): Promise<void> => {
        if (!skipValidation) {
            if (!addressAttributes.addressId ||
                !addressAttributes.country ||
                !addressAttributes.city ||
                !addressAttributes.state ||
                !addressAttributes.openTime ||
                !addressAttributes.closeTime ||
                !addressAttributes.postalCode ||
                !addressAttributes.addressType ||
                !addressAttributes.companyName
            ) {
                throw new Error('Missing some address attributes')
            }
            if (addressAttributes.isBilling && (!addressAttributes.addressId ||
                !addressAttributes.billingContactName ||
                !addressAttributes.billingContactPhone ||
                !addressAttributes.billingContactEmails))
            {
                throw new Error('Missing some address attributes')
            }
            if (addressAttributes.isShipping && (!addressAttributes.addressId ||
                !addressAttributes.shippingContactName ||
                !addressAttributes.shippingContactPhone ||
                !addressAttributes.shippingContactEmails))
            {
                throw new Error('Missing some address attributes')
            }
        } else {
            if (!addressAttributes.addressId || !addressAttributes.country) {
                throw new Error('Missing some address attributes')
            }
        }
        let editAddressResponse
        try {
            editAddressResponse = await AddressBookService.editAddress({
                addressId: addressAttributes.addressId,
                name: addressAttributes.userSpecifiedId,
                description: addressAttributes.description,
                streetAddress1: addressAttributes.streetAddress,
                streetAddress2: addressAttributes.streetAddress2,
                streetAddress3: addressAttributes.streetAddress3,
                city: addressAttributes.city,
                state: addressAttributes.state,
                country: addressAttributes.country,
                postalCode: addressAttributes.postalCode,
                addressType: addressAttributes.addressType,
                detectedAddressType: addressAttributes.detectedAddressType ?? null,
                companyName: addressAttributes.companyName,
                companyLegalName: addressAttributes.companyName,
                shippingContactName: addressAttributes.shippingContactName,
                shippingContactEmails: addressAttributes.shippingContactEmails,
                shippingContactPhone: addressAttributes.shippingContactPhone ?? null,
                shippingContactPhoneExtension: addressAttributes.shippingContactPhoneExtension,
                billingContactName: addressAttributes.billingContactName,
                billingContactEmails: addressAttributes.billingContactEmails,
                billingContactPhone: addressAttributes.billingContactPhone,
                billingContactPhoneExtension: addressAttributes.billingContactPhoneExtension,
                notify: addressAttributes.notify,
                instructions: addressAttributes.instructions,
                openTime: addressAttributes.openTime || null,
                closeTime: addressAttributes.closeTime || null,
                accessorials: addressAttributes.accessorials.length === 0 ? null : addressAttributes.accessorials.map((accessorial) =>
                    ({
                        accessorialId: accessorial.accessorial.id,
                        isRemovable: accessorial.isRemovable,
                    })),
                isShipping: addressAttributes.isShipping,
                isBilling: addressAttributes.isBilling,
                isBillingDefault: addressAttributes.isBillingDefault,
                isShippingDefault: addressAttributes.isShippingDefault,
                currency: addressAttributes.currency ?? Currency.CAD,
                collectAccounts: addressAttributes.collectAccounts,
            })
        } catch (error: any) {
            handleUnauthorizedException(error)
            logger.debug('edit', JSON.stringify(error, null, 4))
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                throw new Error(errorResponse.error?.message || 'Unable to edit address')
            }
            throw new Error('Unable to edit address')
        }

        if (!editAddressResponse.data) {
            logger.debug('edit', JSON.stringify(editAddressResponse, null, 4))
            throw new Error('Unable to edit address')
        }
    },

    remove: async (id: string): Promise<string | null> => {
        let removeAddressResponse
        try {
            removeAddressResponse = await AddressBookService.removeAddress(id)
        } catch (error: any) {
            handleUnauthorizedException(error)
            logger.debug('remove', JSON.stringify(error, null, 4))
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                throw new Error(errorResponse.error?.message || 'Unable to remove address')
            }
            throw new Error('Unable to remove address')
        }

        if (!removeAddressResponse.data) {
            logger.debug('remove', JSON.stringify(removeAddressResponse, null, 4))
            throw new Error('Unable to remove address')
        }

        return removeAddressResponse.data.id
    },
    get: async (id: string, organizationId?: UuidV4): Promise<Address> => {
        let getAddressByIdResponse
        try {
            getAddressByIdResponse = await AddressBookService.getAddressById(id, organizationId)
        } catch (error: any) {
            handleUnauthorizedException(error)
            logger.debug('getById', JSON.stringify(error, null, 4))
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                throw new Error(errorResponse.error?.message || 'Unable to retrieve address by id')
            }
            throw new Error('Unable to retrieve address by id')
        }

        if (!getAddressByIdResponse.data) {
            logger.debug('getById', JSON.stringify(getAddressByIdResponse, null, 4))
            throw new Error('Unable to retrieve address by id')
        }

        return new Address(getAddressByIdResponse.data)
    },
    list: async (paging: AddressBookPaging, filters?: AddressBookFilter): Promise<FilteredAddresses> => {
        let getAddressesResponse
        try {
            getAddressesResponse = await AddressBookService.getAddresses(
                paging.page,
                paging.resultPerPage,
                filters?.searchField,
                filters?.name,
                filters?.address,
                filters?.description,
                filters?.city,
                filters?.state,
                filters?.postalCode,
                filters?.country,
                filters?.companyName,
                filters?.isShipping,
                filters?.isBilling,
                filters?.organizationId,
                filters?.isBillingDefault,
                filters?.isShippingDefault,
                filters?.excludeAddressId,
                filters?.orderBy,
                filters?.order,
            )
        } catch (error: any) {
            handleUnauthorizedException(error)
            logger.debug('list', JSON.stringify(error, null, 4))
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                throw new Error(errorResponse.error?.message || 'Unable to retrieve address list')
            }
            throw new Error('Unable to retrieve address list')
        }

        if (!getAddressesResponse.data) {
            logger.debug('list', JSON.stringify(getAddressesResponse, null, 4))
            throw new Error('Unable to retrieve address list')
        }

        return {
            addresses: getAddressesResponse.data.addresses.map((address) => new Address(address)),
            total: getAddressesResponse.data.paging.items,
        }
    },

    deleteCollectAccountById: async (id: UuidV4): Promise<void> => {
        try {
            await AddressBookService.deleteCollectAccount(id)
        } catch (error: any) {
            handleUnauthorizedException(error)
            logger.debug('delete', JSON.stringify(error, null, 4))
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                throw new Error(errorResponse.error?.message || 'Unable to delete collect account')
            }
            throw new Error('Unable to delete collect account')
        }
    },

    // TODO: Rename to addCompany once Company is implemented
    addBillingAddress: async (addressAttributes: AddressAttributes): Promise<UuidV4> => {
        if (!isAddressComplete(addressAttributes)) {
            throw new Error('Missing some address attributes')
        }

        let addAddressResponse
        try {
            addAddressResponse = await AddressBookService.addBillingAddress({
                addressId: null,
                name: addressAttributes.userSpecifiedId,
                description: addressAttributes.description,
                streetAddress1: addressAttributes.streetAddress,
                streetAddress2:  addressAttributes.streetAddress2,
                streetAddress3:  addressAttributes.streetAddress3,
                city: addressAttributes.city,
                state: addressAttributes.state,
                country: addressAttributes.country ?? Country.CA,
                postalCode: addressAttributes.postalCode,
                addressType: addressAttributes.addressType,
                detectedAddressType: addressAttributes.detectedAddressType ?? null,
                companyName: addressAttributes.companyName,
                companyLegalName: addressAttributes.companyName,
                shippingContactName: addressAttributes.shippingContactName,
                shippingContactEmails: addressAttributes.shippingContactEmails,
                shippingContactPhone: addressAttributes.shippingContactPhone ?? null,
                shippingContactPhoneExtension: addressAttributes.shippingContactPhoneExtension,
                billingContactName: addressAttributes.billingContactName,
                billingContactEmails: addressAttributes.billingContactEmails,
                billingContactPhone: addressAttributes.billingContactPhone,
                billingContactPhoneExtension: addressAttributes.billingContactPhoneExtension,
                notify: addressAttributes.notify,
                instructions: addressAttributes.instructions,
                openTime: addressAttributes.openTime ?? null,
                closeTime: addressAttributes.closeTime ?? null,
                isShipping: addressAttributes.isShipping,
                isBilling: addressAttributes.isBilling,
                isBillingDefault: addressAttributes.isBillingDefault,
                isShippingDefault: addressAttributes.isShippingDefault,
            })
        } catch (error: any) {
            handleUnauthorizedException(error)
            logger.debug('addBillingAddress', JSON.stringify(error, null, 4))
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                if (Array.isArray(errorResponse.error?.message)) {
                    const errorMessages = []
                    for (const msg of errorResponse.error?.message) {
                        if(msg.message){
                            errorMessages.push(msg.message)
                        }
                    }
                    throw new Error(errorMessages.join(', ') || 'Unable to edit address')
                }
                throw new Error(errorResponse.error?.message || 'Unable to add billing address')
            }
            throw new Error('Unable to add billing address')
        }

        if (!addAddressResponse.data) {
            logger.debug('add', JSON.stringify(addAddressResponse, null, 4))
            throw new Error('Unable to add billing address')
        }

        return addAddressResponse.data.id
    },

    // TODO: Rename to editCompany once Company is implemented
    editBillingAddress: async (addressAttributes: BillingAddressAttributes, skipValidation = false): Promise<EditAddressResponse> => {
        if (!skipValidation) {
            if (!addressAttributes.addressId ||
                !addressAttributes.country ||
                !addressAttributes.city ||
                !addressAttributes.state ||
                !addressAttributes.postalCode ||
                !addressAttributes.addressType ||
                !addressAttributes.companyName
            ) {
                throw new Error('Missing some address attributes')
            }
            if (addressAttributes.isBilling && (!addressAttributes.addressId ||
                !addressAttributes.billingContactName ||
                !addressAttributes.billingContactPhone ||
                !addressAttributes.billingContactEmails))
            {
                throw new Error('Missing some address attributes')
            }
            if (addressAttributes.isShipping && (!addressAttributes.addressId ||
                !addressAttributes.shippingContactName ||
                !addressAttributes.shippingContactPhone ||
                !addressAttributes.shippingContactEmails))
            {
                throw new Error('Missing some address attributes')
            }
        } else {
            if (!addressAttributes.addressId || !addressAttributes.country) {
                throw new Error('Missing some address attributes')
            }
        }
        let editAddressResponse: EditAddressResponse
        try {
            editAddressResponse = await AddressBookService.editBillingAddress({
                addressId: addressAttributes.addressId,
                name: addressAttributes.userSpecifiedId,
                description: addressAttributes.description,
                streetAddress1: addressAttributes.streetAddress,
                streetAddress2: addressAttributes.streetAddress2,
                streetAddress3: addressAttributes.streetAddress3,
                city: addressAttributes.city,
                state: addressAttributes.state,
                country: addressAttributes.country,
                postalCode: addressAttributes.postalCode,
                addressType: addressAttributes.addressType,
                detectedAddressType: addressAttributes.detectedAddressType ?? null,
                companyName: addressAttributes.companyName,
                companyLegalName: addressAttributes.companyName,
                shippingContactName: addressAttributes.shippingContactName,
                shippingContactEmails: addressAttributes.shippingContactEmails,
                shippingContactPhone: addressAttributes.shippingContactPhone ?? null,
                shippingContactPhoneExtension: addressAttributes.shippingContactPhoneExtension,
                billingContactName: addressAttributes.billingContactName,
                billingContactEmails: addressAttributes.billingContactEmails,
                billingContactPhone: addressAttributes.billingContactPhone,
                billingContactPhoneExtension: addressAttributes.billingContactPhoneExtension,
                notify: addressAttributes.notify,
                instructions: addressAttributes.instructions,
                openTime: addressAttributes.openTime || null,
                closeTime: addressAttributes.closeTime || null,
                isShipping: addressAttributes.isShipping,
                isBilling: addressAttributes.isBilling,
                isBillingDefault: addressAttributes.isBillingDefault,
                isShippingDefault: addressAttributes.isShippingDefault,
                currency: addressAttributes.currency ?? Currency.CAD,
            })
        } catch (error: any) {
            handleUnauthorizedException(error)
            logger.debug('edit', JSON.stringify(error, null, 4))
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                if (Array.isArray(errorResponse.error?.message)) {
                    const errorMessages = []
                    for (const msg of errorResponse.error?.message) {
                        if(msg.message){
                            errorMessages.push(msg.message)
                        }
                    }
                    throw new Error(errorMessages.join(', ') || 'Unable to edit address')
                }
                throw new Error(errorResponse.error?.message || 'Unable to edit address')
            }
            throw new Error('Unable to edit address')
        }

        if (!editAddressResponse.data) {
            logger.debug('edit', JSON.stringify(editAddressResponse, null, 4))
            throw new Error('Unable to edit address')
        }

        return editAddressResponse
    },
})
